<template>
	<div id="schoolCalendar">
		<index>
			<el-breadcrumb separator="/">
				<el-breadcrumb-item>常规</el-breadcrumb-item>
				<el-breadcrumb-item><a style="color: #000000;">学校校历</a></el-breadcrumb-item>
			</el-breadcrumb>
			<div class="content u-f">
				<div class="left">
					<full-calendar :options="calendarOptions"></full-calendar>
				</div>
				<div class="right">
					<div class="u-f-item b-t u-f-jsb" style="padding: 15px;">
						<div class="font_16">{{today}} <span class="num">{{eventsList.length}}</span></div>
						<div><el-button size="small" @click="NodeVisible=true">添加事件</el-button></div>
					</div>
					<div class="event-list">
						<div class="event-item" v-for="(item,index) in eventsList" :key="index" v-if="eventsList.length">
							<div class="title"><span></span>{{item.title}}</div>
							<div class="u-f-item event"><i class="el-icon-time"></i><span>{{item.time}}</span></div>
							<div class="u-f event"><i class="el-icon-edit"></i><span>{{item.remark}}</span></div>
						</div>
						<el-empty :image-size="100" v-if="!eventsList.length" style="margin-top: 200px;"></el-empty>
					</div>
				</div>
			</div>
		</index>

		<!--添加校历 -->
		<el-dialog title="添加事件" :visible.sync="NodeVisible" width="500px" :before-close="handleClose">
			<div class="edit-box">
				<div class="u-f-item input-box">
					<div class="input-lebal">
						<span>*</span>
						事件名称
					</div>
					<el-input v-model="title" placeholder="请输入事件名称" style="width: 75%;"></el-input>
				</div>
				<div class="u-f-item input-box">
					<div class="input-lebal">
						<span>*</span>
						日期
					</div>
					<el-date-picker value-format="yyyy-MM-dd"  format="yyyy-MM-dd" v-model="date" type="date" placeholder="选择日期" style="width: 75%;"></el-date-picker>
				</div>
				<div class="u-f-item input-box">
					<div class="input-lebal">
						<span>*</span>
						时间
					</div>
					<el-time-select v-model="time" :picker-options="{start: '08:30',step: '00:15',end: '18:30'}" placeholder="选择时间" style="width: 75%;"></el-time-select>
				</div>
				<div class="u-f input-box">
					<div class="input-lebal">
						<span>*</span>
						备注
					</div>
					<el-input v-model="remark" type="textarea" :rows="5" placeholder="请输入事件名称" style="width: 75%;"></el-input>
				</div>
			</div>
			<span slot="footer" class="dialog-footer u-f-item u-f-jsb">
				<el-button @click="NodeVisible=false" size="small">取 消</el-button>
				<el-button type="primary" size="small" @click="setGroupRule">确 定</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
import index from '@/components/common/index.vue';
import FullCalendar from '@fullcalendar/vue';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import {calendar} from "../../utils/calendar" //农历计算方法
import { formatDate } from "../../utils/formatDate.js"
export default {
	components: {
		index,
		FullCalendar,
		dayGridPlugin,
		interactionPlugin
	},
	data() {
		return {
			NodeVisible: false,
			calendarOptions: {
				lunarCalendar: true,
				locale: 'zh', // 中文
				buttonText: {
					// 按钮文本
					today: '今天',
					month: '月视图',
					week: '周视图'
					//   day: "日视图",
				},
				plugins: [dayGridPlugin, interactionPlugin],
				headerToolbar: {
					left: 'prev,next today', // 左侧按钮  点击触发对应顺序的字符串代表的事件
					center: 'title', // 中间标题
					right: 'dayGridMonth,dayGridWeek' // 右侧按钮 点击触发对应顺序的字符串代表的事件
				},
				initialView: 'dayGridMonth', // 初始化 开始视图类型
				editable: false, // 是否可以拖拽编辑
				selectable: true,
				dateClick: this.handleDateClick, // 点击日期触发事件
				events: [],
				height: '100%',
				eventClick: this.handleEventClick ,// 点击 添加的日程触发事件
				views:{
					//对应月视图
					dayGridMonth:{
						displayEventTime:false,//是否显示时间
						dayCellContent(item){
						  let _date= item.date
						  let _dateF=calendar.solar2lunar(_date.getFullYear(),_date.getMonth() + 1,_date.getDate())
						  return {html:`<div style="padding:10px;display:flex;justify-content: space-between;width: 100%;"><div style="color:#aaa">${_dateF.IDayCn}</div><div>${_dateF.cDay}</div></div>`}
						},
					}
				}
			},
			title:'',
			date:'',
			time:'',
			remark:'',
			eventsList:[],
			today:''
		};
	},
	mounted() {
		this.getSchoolCourseList();
		this.info()
	},
	methods: {
		// 点击事件
		handleDateClick(event) {
			this.today = event.dateStr;
			this.getSchoolCourseDetail(event.dateStr)
		},
		// 查看更多
		handleEventClick(day, events, jsEvent) {
			console.log('moreCLick', day, events, jsEvent);
		},
		handleClose(done) {
			done();
			this.init()
		},
		setGroupRule(){
			if(this.title==''||!this.title){return this.$message.error('请输入事件名称')}
			if(this.date==''||!this.date){return this.$message.error('请选择日期')}
			if(this.time==''||!this.time){return this.$message.error('请选择时间')}
			if(this.remark==''||!this.remark){return this.$message.error('请输入备注')}
			let data = {
				title:this.title,
				remark:this.remark,	
				date:this.date,
				time:this.time
			}
			this.$api.setting.addSchoolCourse(data).then(res=>{
				if(res.data.code==1){
					this.$message.success('添加成功');
					this.getSchoolCourseList();
					this.info()
					this.NodeVisible = false;
					this.init()
				}else{
					this.$message.error(res.data.msg)
				}
			})
		},
		init(){
			this.title=='';
			this.date=='';
			this.time=='';
			this.remark=='';
		},
		getSchoolCourseList(){
			this.$api.setting.getSchoolCourseList({
				semester_id:window.localStorage.getItem('semester_id')
			}).then(res=>{
				if(res.data.code==1){
					let data = res.data.data;
					let arr = [];
					for(let i in data){
						arr.push({
							title: data[i].title,
							start: data[i].date,
							end: data[i].date, // 结束日期（不包含当天）
							id: data[i].id,
							color: '#007AFF'
						})
					}
					this.calendarOptions.events = arr;
				}
			})
		},
		getSchoolCourseDetail(date){
			this.$api.setting.getSchoolCourseDetail({
				date:date
			}).then(res=>{
				if(res.data.code==1){
					this.eventsList = res.data.data;
				}
			})
		},
		info(){
			let date = new Date();
			let year = date .getFullYear();
			let Month = (date .getMonth()+1);
			let day = date .getDate();
			let today = year +'-'+Month+'-'+day;
			this.today = today;
			this.getSchoolCourseDetail(today)
		}
	}
};
</script>

<style lang="scss">
#schoolCalendar {
	.content {
		height: 85vh;
		margin-top: 20px;
		.left {
			width: 80%;
			background-color: #ffffff;
			padding: 20px;
			.fc .fc-button-primary:not(:disabled):active, .fc .fc-button-primary:not(:disabled).fc-button-active {
			    color: #fff;
			    color: var(--fc-button-text-color, #fff);
			    background-color:#007aff;
			    background-color: var(--fc-button-active-bg-color, #007aff);
			    border-color: #007aff;
			    border-color: var(--fc-button-active-border-color, #007aff);
			}
			.fc .fc-button-primary {
				color: #007aff;
				color: var(--fc-button-text-color, #007aff);
				background-color: #E4F1FF;
				background-color: var(--fc-button-bg-color, #E4F1FF);
				border-color: #E4F1FF;
				border-color: var(--fc-button-border-color, #E4F1FF);
			}
		}
		.right {
			width: 28%;
			background-color: #ffffff;
			margin-left: 20px;
			.num{
				background-color: #E4F1FF;
				color: #2D8CF0;
				width: 30px;
				height: 30px;
				border-radius: 50%;
				display: inline-block;
				text-align: center;
				line-height: 30px;
				margin-left: 10px;
			}
			.event-list {
				padding: 20px;
				overflow-y: scroll;
				height: 73vh;
				.event-item{
					background-color: #F9F9F9;
					padding: 20px;
					margin-bottom: 15px;
					.title{
						font-size: 16px;
						span{
							width: 10px;
							height: 10px;
							background-color: #007AFF;
							display: inline-block;
							margin-right: 10px;
							border-radius: 50%;
						}
					}
					.event{
						margin-top: 15px;
						color: #aaaaaa;
						i{
							font-size: 18px;
							margin-right: 10px;
						}
					}
				}
			}
		}
	}
	.el-dialog__header {
		border-bottom: 1px solid #eeeeee;
		padding: 10px 20px;
		.el-dialog__title {
			font-size: 16px;
		}
	}
	.el-dialog__body {
		padding: 0 20px 35px 20px;
		height: 400px;
		overflow-y: auto;
		.edit-box {
			.input-box {
				margin-top: 35px;
				padding-left: 15px;
				.input-lebal {
					width: 20%;
					text-align: right;
					color: #aeb5b8;
					margin-right: 10px;
					span {
						color: #ff7a65;
						margin-right: 3px;
					}
				}
				.input-lebal1 {
					width: 5vw;
					text-align: right;
					color: #aeb5b8;
					margin-right: 10px;
					span {
						color: #ff7a65;
						margin-right: 3px;
					}
				}
				.el-icon-remove-outline {
					font-size: 1.5625vw;
					color: #d7d9db;
					margin-left: 10px;
				}
				.upload-file {
					position: absolute;
					left: 0;
					right: 0;
					top: 0;
					bottom: 0;
					opacity: 0;
					cursor: pointer;
				}
				.file_box {
					padding: 5px;
					.el-icon-circle-check {
						display: block;
					}
					.el-icon-circle-close {
						display: none;
					}
				}
				.file_box:hover {
					color: #007aff;
					background-color: #f6f8fb;
					.el-icon-circle-check {
						display: none;
					}
					.el-icon-circle-close {
						display: block;
					}
				}
				.el-upload__tip {
					color: #ccc;
				}

				.table-head {
					height: 50px;
					min-height: 21.33333333px;
					background-color: #f6f8fb;
					border-bottom: 1px solid #f0f2f5;
					.class_name {
						font-weight: 700;
						height: 100%;
						border-right: 1px solid #f0f2f5;
						width: 4.85416667vw;
						min-width: 39.46666667px;
						color: #68b0fd;
						font-size: 0.729vw;
					}
					.week_name {
						height: 100%;
						border-right: 1px solid #f0f2f5;
						flex: 1;
						min-width: 64px;
						border-right: 1px solid #f0f2f5;
						color: #8b8b8d;
						font-size: 0.7291666666666666vw;
					}
				}
				.table-item {
					height: 50px;
					min-height: 26.66666667px;
					border-bottom: 1px solid #f0f2f5;
					.time_name {
						height: 100%;
						background-color: #f6f8fb;
						width: 4.85416667vw;
						min-width: 39.46666667px;
						border-right: 1px solid #f0f2f5;
						color: #8b8b8d;
						font-size: 0.7291666666666666vw;
					}
					.cell {
						position: relative;
						overflow-x: hidden;
						height: 100%;
						border-right: 1px solid #f0f2f5;
						width: 6.25vw;
						flex: 1;
						min-width: 64px;
						cursor: pointer;
						display: -webkit-box;
						display: -ms-flexbox;
						display: flex;
						-webkit-box-pack: center;
						-ms-flex-pack: center;
						justify-content: center;
						-webkit-box-align: center;
						-ms-flex-align: center;
						align-items: center;
						-webkit-box-orient: vertical;
						-webkit-box-direction: normal;
						-ms-flex-direction: column;
						flex-direction: column;
						transition: all 0.2s ease 0s;
						-moz-transition: all 0.2s ease 0s;
						-webkit-transition: all 0.2s ease 0s;
						-o-transition: all 0.2s ease 0s;
						-ms-transition: all 0.2s ease 0s;
						p {
							white-space: nowrap;
							overflow: hidden;
							text-overflow: ellipsis;
							width: 4.94791667vw;
							min-width: 50.66666667px;
							margin: 0;
							padding: 0;
							border: 0;
							text-align: center;
							// font-size: 0.7291666666666666vw;
						}
						.cell_subject {
							color: #6f6f6f;
							margin-bottom: 0.26041667vw;
						}
						.cell_teacher {
							color: #a9b8c0;
						}
					}
					.active {
						background-color: #1890ff;
						.cell_teacher {
							color: #ffffff;
						}
						.cell_subject {
							color: #ffffff;
						}
					}
				}
				.teacher-list {
					display: flex;
					flex-wrap: wrap;
					width: 72%;
					.teacher-item {
						width: 100px;
						height: 30px;
						line-height: 30px;
						border-radius: 5px;
						text-align: center;
						margin-right: 10px;
						background-color: #eeeeee;
						margin-bottom: 10px;
						cursor: pointer;
					}
					.active {
						background-color: #007aff;
						color: #ffffff;
					}
				}
			}
			.avatar-uploader .el-upload {
				border: 1px dashed #d9d9d9;
				border-radius: 6px;
				cursor: pointer;
				position: relative;
				overflow: hidden;
			}
			.avatar-uploader .el-upload:hover {
				border-color: #409eff;
			}
			.avatar-uploader-icon {
				font-size: 28px;
				color: #8c939d;
				width: 90px;
				height: 90px;
				line-height: 90px;
				text-align: center;
			}
			.avatar {
				width: 90px;
				height: 90px;
				display: block;
			}
		}
	}
	.el-dialog__footer {
		padding: 15px 20px 15px;
		box-shadow: 0px -5px 10px -5px #eeeeee;
	}
}
</style>
